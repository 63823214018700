<template>
    <div>
      <img v-if="imageData" :src="imageData" alt="Image preview" style="max-width:100%; max-height: 200px;">
      <i v-else class="">No image uploaded yet</i>
      <v-file-input filled width="200px" prepend-icon="fa fa-camera" accept="image/*" :rules="rules" label="Select an image" v-model="file" v-on:change="updatePreview"></v-file-input>
      <v-alert v-if="syncResult && syncResult.status == 'OK'" type="success">All good! Your picture is saved successfully!</v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'INFO'" type="info"><strong>{{ syncResult.msg }}</strong></v-alert>
      <v-alert v-else-if="syncResult && syncResult.status == 'ERROR'" type="error">Unfortunately, we couldn't process your file: <strong>{{ syncResult.msg }}</strong></v-alert>
      <v-alert v-if="previewMode" type="info">Preview of how your image will look. Click Upload to save.</v-alert>
      <v-btn v-if="previewMode" color="success" @click="uploadFile">Upload image</v-btn>
    </div>
</template>

<script>

import eventManagerService from "@/services/eventManagerService";
import profileService from "@/services/profileService";

export default {
  name: "EventImageUploadForm",
  props: {
    img: String,
    type: String,
    eventId: String,
    groupId: String,
  },
  data: function() {
    return {
      file: null,
      previewMode: false,
      syncResult: null,
      previewImgData: null,
      rules: [
        value => !value || value.size < 5000000 || 'Image size should be less than 5 MB!',
      ]
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async updatePreview() {
      if (this.file) {
        this.syncResult = null;
        this.previewImgData = URL.createObjectURL(this.file);
        this.previewMode = true;
      }
    },
    async uploadFile() {
      this.syncResult = null;
      let formData = new FormData()
      if (this.file) {
        this.previewImgData = URL.createObjectURL(this.file);
        formData.append("file", this.file, this.file.name);
        var response = null;
        if (this.eventId){
          response = await eventManagerService.upload(this.eventId, this.type, formData);
        }
        else if (this.groupId) {
          response = await profileService.uploadGroupImage(this.groupId, this.type, formData);
        }
        this.syncResult = response.data;
        if (this.syncResult.status == "OK") {
          this.previewMode = false;
          this.$emit('change');
        }
      }
      else {
        this.syncResult = {
          status: "ERROR",
          msg: "Please select a file to upload."
        };
      }
    },
  },
  computed: {
    imageData() {
      return this.previewImgData || this.img;
    }
  },
};
</script>

<style scoped>
</style>